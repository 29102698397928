import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Offer } from '../models/offer.model';

import { environment } from '../../environments/environment';
import { logClass } from './logger.decorator';

@logClass()
@Injectable()
export class OfferService {
  baseHref = `${environment.apiUrl}/api/admin/offers`;
  reviewBaseHref = `${environment.apiUrl}/api/reviews`;

  constructor(private http: HttpClient) {}

  getPriceIncOffer(offerId: number): Observable<any> {
    const url = `${this.baseHref}/offer-price-inc/${offerId}`;
    return this.http.get(url);
  }

  getOffers(): Observable<Offer[]> {
    const url = `${this.baseHref}`;
    return this.http.get<Offer[]>(url);
  }

  getOfferByTaskIdAndStatus(
    taskId: number,
    status?: string,
  ): Observable<Offer> {
    if (!taskId) {
      return;
    }
    let url = `${this.baseHref}/from-task/${taskId}`;
    if (status) {
      url += `/${status}`;
    }
    return this.http.get<Offer>(url);
  }

  getAllOffersFiltered(
    userId: number,
    page?: number,
    status?: string,
    timePeriod?: { start?: Date; end?: Date },
    searchString?: string,
  ): Observable<Offer[]> {
    let url = `${this.baseHref}/user/${userId}`;
    const params: any = {};

    if (page !== undefined) {
      params.page = page;
    }
    if (status) {
      params.status = status;
    }
    if (timePeriod) {
      if (timePeriod.start) {
        params.start = timePeriod.start;
      }
      if (timePeriod.end) {
        params.end = timePeriod.end;
      }
    }
    if (searchString) {
      params.search = searchString;
    }

    return this.http.get<Offer[]>(url, { params });
  }

  getOfferCountByUserId(userId: number): Observable<any> {
    const url = `${this.baseHref}/user/${userId}/count`;
    return this.http.get(url);
  }

  getOfferById(id: number): Observable<Offer> {
    const url = `${this.baseHref}/${id}`;
    return this.http.get<Offer>(url);
  }

  getUnpaidCompletedOffer(): Observable<Offer[]> {
    const url = `${this.baseHref}/unpaid-completed`;
    return this.http.get<Offer[]>(url);
  }

  editOffer(id: number, data: any): Observable<Offer> {
    if (data.id) {
      delete data.id;
    }
    const url = `${this.baseHref}/${id}`;
    return this.http.put<Offer>(url, data);
  }

  realEditOffer(id: number, data: any): Observable<any> {
    const url = `${environment.apiUrl}/api/offers/edit/${id}`;
    return this.http.post(url, data);
  }

  deleteOffer(id: number): Observable<Offer> {
    const url = `${this.baseHref}/${id}`;
    return this.http.delete<Offer>(url);
  }

  getReviewsForOffer(offerId: number): Observable<any> {
    const url = `${this.reviewBaseHref}/public/offer/${offerId}`;
    return this.http.get(url);
  }
}
